<!--
 * @Author: Jerry
 * @Date: 2021-03-01 10:09:51
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 09:56:29
 * @Description: 查看物流 - 地图打点
-->
<template>
  <div>
    <el-dialog title="物流信息" :visible.sync="dialogVisible" width="1000px" top="5vh" :append-to-body="true" :destroy-on-close="true">
      <div>
        <div>
          <el-form label-position="right" label-width="120px">
            <el-row>
              <el-col :span="9">
                <el-form label-position="right" label-width="120px">
                  <el-form-item label="物流类型：">
                    {{ freightType[basicInfo.freightType] }}
                  </el-form-item>
                  <el-form-item label="车牌信息：">
                    {{ basicInfo.plateNumber }}
                  </el-form-item>
                  <el-form-item label="车牌颜色：">
                    {{ basicInfo.carColor }}
                  </el-form-item>
                  <el-form-item label="货运开始时间：">
                    {{ formatDate(basicInfo.freightTimeStart * 1000) }}
                  </el-form-item>
                  <el-form-item label="货运结束时间：">
                    {{ formatDate(basicInfo.freightTimeEnd * 1000) }}
                  </el-form-item>
                  <el-form-item label="车辆轨迹："></el-form-item>
                </el-form>
              </el-col>
              <el-col :span="12">
                <el-form label-position="right" label-width="120px" :inline="true">
                  <el-form-item label="发货单：">
                    <contractButton v-if="basicInfo.dispatchBill != ''" :filePath="basicInfo.dispatchBill"></contractButton>
                    <span v-else>暂无发货单</span>
                  </el-form-item>
                  <el-form-item label="收货单：">
                    <contractButton v-if="basicInfo.receivingNote != ''" :filePath="basicInfo.receivingNote"></contractButton>
                    <span v-else>暂无收货单</span>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-loading="loading" element-loading-text="地图路线加载中" element-loading-spinner="el-icon-loading">
          <baidu-map :center="center" :zoom="12" :scroll-wheel-zoom="true" style="height: 500px">
            <bm-polyline :path="polylinePath" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="5" :editing="false"></bm-polyline>
            <!--<bm-marker
              v-for="(marker, i) of markers"
              :key="i"
              :icon="{ url: marker.url, size: { width: 50, height: 50 } }"
              :position="{ lng: marker.lng, lat: marker.lat }"
            >
            </bm-marker>-->
            <!-- <bm-marker
              :icon="{ url: marker.url, size: { width: 50, height: 50 } }"
              :position="{ lng: marker.lng, lat: marker.lat }"
            >
            </bm-marker>  -->
            <bm-marker :icon="{ url: startIcon, size: { width: 56, height: 32 } }" :position="{
                lng: Number(startMark.lng),
                lat: Number(startMark.lat),
              }"></bm-marker>
            <bm-marker :icon="{ url: endIcon, size: { width: 32, height: 32 } }" :position="{ lng: Number(endMark.lng), lat: Number(endMark.lat) }"></bm-marker>
          </baidu-map>
        </div>
        <!-- <div v-if="basicInfo.status < 1">
          暂无物流信息
        </div> -->
      </div>
      <!-- <div>
        <logistics-information ref="logisticsInformation"></logistics-information>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import { getWebTrackInfo, findLogisticsMessage } from "@/api/product";
import {
  freightType,
  dayJsFormat,
  formatStatus,
  formatPayStatus,
  formatDate
} from "@/util/util";
import moment from "moment";

import contractButton from "@/components/contractButton";

// 插入 100 个随机点
const markers = [];
// for (let i = 0; i < 10; i++) {
//   markers.push({
//     lng: Math.random() * 40 + 85,
//     lat: Math.random() * 30 + 21,
//     url: "https://cdn.tipe.io/tipe/tipe-cat-no-text.svg",
//     showFlag: false,
//   });
// }
export default {
  components: { BmMarker, contractButton },
  data() {
    return {
      loading: false,
      center: { lng: 118.620447, lat: 24.718035 },
      polylinePath: [],
      dialogVisible: false,
      activities: [],
      basicInfo: {},
      markers,
      freightType,
      startIcon: "/svg/newStart.svg",
      startMark: {
        lng: null,
        lat: null
      },
      endIcon: "/svg/endIcon.svg",
      endMark: {
        lng: null,
        lat: null
      }
    };
  },
  watch: {},
  methods: {
    dayJsFormat,
    formatStatus,
    formatPayStatus,
    formatDate,
    showModal(id, basicInfo) {
      console.log("basicInfo", basicInfo);
      if (basicInfo.plateNumber) {
        this.polylinePath = [];
        this.startMark = {
          lng: null,
          lat: null
        };
        this.endMark = {
          lng: null,
          lat: null
        };
        this.findLogisticsMessage(id);
      } else {
        this.$message({
          type: "warning",
          message: "暂无上传车牌信息"
        });
      }

      this.$set(this, "basicInfo", basicInfo);
      if (
        basicInfo.carColor == "" ||
        basicInfo.carColor == null ||
        basicInfo.carColor == undefined
      ) {
        this.$set(this.basicInfo, "carColor", "无");
      }
      this.dialogVisible = true;
    },
    findLogisticsMessage(logisticsId) {
      this.$set(this, "activities", []);
      this.$set(this, "polylinePath", []);
      this.loading = true;
      findLogisticsMessage({
        // transportNo, //: 'YD202106100001' 测试用数据
        logisticsId
      }).then(res => {
        this.loading = false;
        if (res.success) {
          if (res.data.length > 0) {
            this.$nextTick(() => {
              this.$set(this, "activities", res.data);
              this.$set(this, "endMark", {
                lng: res.data[res.data.length - 1].lng,
                lat: res.data[res.data.length - 1].lat
              });
              this.$set(this, "center", {
                lng: res.data[res.data.length - 1].lng,
                lat: res.data[res.data.length - 1].lat
              });
              this.$set(this, "startMark", {
                lng: res.data[0].lng,
                lat: res.data[0].lat
              });
              this.$set(this, "polylinePath", res.data);
            });
          }
        } else {
          this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-form-item__content {
  display: none;
}
</style>
