<!--
 * @Author: Jerry
 * @Date: 2021-03-18 23:23:41
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-18 23:25:02
 * @Description: file content
-->
<template>
  <div>
    <el-button
      type="text"
      size="mini"
      @click="filePreview(filePath)"
      v-if="
        judgeFileType(filePath) == 'pdf' &&
        filePath
      "
      >点击预览</el-button
    >
    <el-image
      style="width: 100px; height: 100px"
      :src="filePath"
      :preview-src-list="[filePath]"
      :z-index="99999"
      v-if="
        judgeFileType(filePath) == 'image' &&
        filePath
      "
    >
    </el-image>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  props: {
    filePath: {
      type: String,
      default: ""
    }
  },
  computed: {},
  watch: {},
  methods: {
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
  },
};
</script>

<style scoped>
</style>
