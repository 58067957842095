<!--
 * @Author: Jerry
 * @Date: 2021-03-01 10:09:51
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-14 22:52:14
 * @Description: 查看物流
-->
<template>
  <div>
    <el-dialog
      title="物流信息"
      :visible.sync="dialogVisible"
      width="600px"
      top="5vh"
    >
      <div>
        <div style="margin-bottom: 20px">
          <el-image
            class="avatar"
            v-if="activities.logo"
            :src="activities.logo"
          ></el-image>
          <div class="text">{{ activities.typename }}</div>
          <div class="text">{{ activities.waybillNumber }}</div>
        </div>
        <div style="max-height: 500px; overflow:auto">
          <el-timeline :reverse="false">
            <el-timeline-item
              v-for="(activity, index) in activities.array"
              :key="index"
              :timestamp="activity.time"
            >
              {{ activity.status }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <!-- <div>
        <logistics-information ref="logisticsInformation"></logistics-information>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { findLogisticsMessage } from "@/api/product";
export default {
  data() {
    return {
      dialogVisible: false,
      activities: {
        array: [],
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    showModal(id) {
      this.$set(this, "activities", {
        array: [],
      });
      this.dialogVisible = true;
      this.findLogisticsMessage(id);
    },
    findLogisticsMessage(id) {
      findLogisticsMessage({
        logisticsId: id,
      }).then((res) => {
        if (res.success) {
          this.$set(this, "activities", res.data);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 35px;
  height: 35px;
}
.text {
  display: inline-block;
  margin-left: 10px;
  overflow: hidden;
  line-height: 35px;
  font-size: 16px;
}
</style>
