<!--
 * @Author: Jerry
 * @Date: 2021-03-14 22:34:35
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 09:41:23
 * @Description: 物流列表
-->
<template>
  <div>
    <el-dialog
        title="物流信息"
        :visible="visible"
        width="1280px"
        top="5vh"
        :append-to-body="true"
        @close="onClose"
    >
      <el-table
      :data="tableData"
      border
      size="mini"
    >
      <el-table-column prop="transportNo" label="运单号"/>
      <el-table-column prop="createDate" label="运单生成时间" width="150">
        <template slot-scope="scope">
          {{dayJsFormat(scope.row.createDate) || '--'}}
        </template>
      </el-table-column>
      <el-table-column prop="factSendDate" label="实际发货时间" width="150">
        <template slot-scope="scope">
          {{ dayJsFormat(scope.row.factSendDate) || '--'}}
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" width="150"/>
      <el-table-column prop="sendName" label="发货人" width="90px"/>
      <el-table-column prop="sendTel" label="发货人电话" width="150"/>
<!--      <el-table-column prop="status" label="状态" width="150px">-->
<!--        <template slot-scope="scope">-->
<!--          <div v-if="isEdit">-->
<!--            <el-switch-->
<!--              size="small"-->
<!--              active-text="正常"-->
<!--              inactive-text="异常"-->
<!--              v-model="scope.row.status"-->
<!--              active-color="#13ce66"-->
<!--              inactive-color="#ff4949"-->
<!--              :active-value="0"-->
<!--              :inactive-value="1"-->
<!--            >-->
<!--            </el-switch>-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <span v-if="scope.row.status == 0" class="co_green">正常</span>-->
<!--            <span v-else class="co_f00">异常</span>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="action" label="操作" v-if="!isEdit" width="110px">
        <template slot-scope="scope">
          <el-button
            @click="findLogisticsMessage(scope.row)"
            type="primary"
            size="mini"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 物流信息 -->
    <logisticsInfoByMap ref="logisticsInfoByMap"></logisticsInfoByMap>
  </div>
</template>

<script>
import { freightType, dayJsFormat } from "@/util/util";
import logisticsInfoByMap from "./logisticsInfoByMap";
import {mapGetters} from "vuex";
import moment from 'moment'
export default {
  components: {logisticsInfoByMap},
  name: "",
  data() {
    return {
      freightType,
      dayJsFormat
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "1",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    onClickClose: {
      type: Function,
      default: ()=>{
        return
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  watch: {},
  methods: {

    showModal(item) {
      this.dialogVisible = true;
    },
    findLogisticsMessage(row) {
      console.log('row', row.status)
      this.$refs.logisticsInfoByMap.showModal(row.id, row);
    },
    // 文件预览
    filePreview(url) {
      window.open(url, "_blank");
    },
    // 校验文件格式
    judgeFileType(fileName) {
      // 后缀获取
      var suffix = "";
      var result = "";
      try {
        var flieArr = fileName.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "image";
        return result;
      }
      // 匹配PDF
      var pdflist = ["pdf"];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = "pdf";
        return result;
      }
    },
    onClose(){
      console.log(1)
      this.onClickClose()
    }
  },
};
</script>

<style scoped>
</style>
