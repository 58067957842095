<!--
 * @Author: Jerry
 * @Date: 2021-03-01 10:09:51
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-26 09:56:29
 * @Description: 查看物流 - 地图打点
-->
<template>
  <div>
    <el-dialog
        title="物流信息"
        :visible.sync="dialogVisible"
        width="1000px"
        top="5vh"
        :append-to-body="true"
    >
      <div>
        <div>
          <el-form label-position="right" label-width="120px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="运单号：">
                  {{ basicInfo.transportNo }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户名称：">
                  <div :title="basicInfo.customerName" class="el-form-item-content">
                  {{ basicInfo.customerName }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">

                <el-form-item label="货物名称：">
                  <div :title="basicInfo.goodsTypeName" class="el-form-item-content">
                  {{ basicInfo.goodsTypeName }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="货位重量(吨)：">
                  {{ basicInfo.goodsWeight }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="货物单位：">
                  {{ basicInfo.freightUnit }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际发货量：">
                  {{ basicInfo.sendGoodsNum }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际发货时间：">
                  {{ dayJsFormat(basicInfo.factSendDate) || '' }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际交货量：">
                  {{ basicInfo.receiveGoodsNum }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="实际交货时间：">
                  {{ dayJsFormat(basicInfo.factReachDate) || '' }}
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="签收时间：">
                  {{ dayJsFormat(basicInfo.signDate) || ''}}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="省份：">
                  {{ basicInfo.startProvinceName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="城市：">
                  {{ basicInfo.startCityName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="区县：">
                  {{ basicInfo.startCountyName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发货地址名称：">
                  <div :title="basicInfo.startAddrName" class="el-form-item-content">
                    {{ basicInfo.startAddrName }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="详细地址：">
                  <div :title="basicInfo.startAddr" class="el-form-item-content">
                  {{ basicInfo.startAddr }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发货人：">
                  {{ basicInfo.sendName }}
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="发货电话：">
                  {{ basicInfo.sendTel }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="省份：">
                  {{ basicInfo.endProvinceName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="城市：">
                  {{ basicInfo.endCityName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="区县：">
                  {{ basicInfo.endCountyName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="收货地址名称：">
                  <div :title="basicInfo.endAddrName" class="el-form-item-content">
                    {{ basicInfo.endAddrName }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="详细地址：">
                  <div :title="basicInfo.endAddr" class="el-form-item-content">
                  {{ basicInfo.endAddr }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="收货人：">
                  {{ basicInfo.receiveName }}
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="收货人电话：">
                  {{ basicInfo.receiveTel }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="车牌号：">
                  {{ basicInfo.vehicleNo }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="司机：">
                  {{ basicInfo.driverName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="司机电话：">
                  {{ basicInfo.driverPhone }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算对象：">
                  {{ basicInfo.paymentName }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="运单状态：">
                  {{ formatStatus(basicInfo.status) }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="支付状态：">
                  {{ formatPayStatus(basicInfo.payStatus) }}
                </el-form-item>
              </el-col>
              <el-col :span="8">
              <el-form-item label="车辆轨迹："/>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-if="basicInfo.status > 1" v-loading="loading" element-loading-text="地图路线加载中" element-loading-spinner="el-icon-loading">
          <baidu-map
              :center="center"
              :zoom="12"
              :scroll-wheel-zoom="true"
              style="height: 500px"
          >
            <bm-polyline
                :path="polylinePath"
                stroke-color="blue"
                :stroke-opacity="0.5"
                :stroke-weight="5"
                :editing="false"
            ></bm-polyline>
            <!--<bm-marker
                v-for="(marker, i) of markers"
                :key="i"
                :icon="{ url: marker.url, size: { width: 50, height: 50 } }"
                :position="{ lng: marker.lng, lat: marker.lat }"
            >
            </bm-marker>-->
            <!-- <bm-marker
              :icon="{ url: marker.url, size: { width: 50, height: 50 } }"
              :position="{ lng: marker.lng, lat: marker.lat }"
            >
            </bm-marker>  -->
            <bm-marker :icon="{ url: startIcon, size: { width: 56, height: 32 } }" :position="{lng: Number(startMark.lng),lat: Number(startMark.lat),}"></bm-marker>
            <bm-marker :icon="{ url: endIcon, size: { width: 32, height: 32 } }" :position="{ lng: Number(endMark.lng), lat: Number(endMark.lat) }"></bm-marker>
          </baidu-map>
        </div>
        <div v-if="basicInfo.status < 1">
          暂无物流信息
        </div>
      </div>
      <!-- <div>
        <logistics-information ref="logisticsInformation"></logistics-information>
      </div> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import BmMarker from "vue-baidu-map/components/overlays/Marker";
  import { getWebTrackInfo } from "@/api/product";
  import { freightType, dayJsFormat, formatStatus, formatPayStatus } from "@/util/util";
  import moment from 'moment'

  // 插入 100 个随机点
  const markers = [];
  // for (let i = 0; i < 10; i++) {
  //   markers.push({
  //     lng: Math.random() * 40 + 85,
  //     lat: Math.random() * 30 + 21,
  //     url: "https://cdn.tipe.io/tipe/tipe-cat-no-text.svg",
  //     showFlag: false,
  //   });
  // }
  export default {
    components: { BmMarker },
    data() {
      return {
        loading: false,
        center: { lng: 118.620447, lat: 24.718035 },
        polylinePath: [],
        dialogVisible: false,
        activities: [],
        basicInfo: {},
        markers,
        freightType,
        startIcon: "/svg/newStart.svg",
        startMark: {
          lng: null,
          lat: null,
        },
        endIcon: "/svg/endIcon.svg",
        endMark: {
          lng: null,
          lat: null,
        },
      };
    },
    watch: {},
    methods: {
      dayJsFormat,
      formatStatus,
      formatPayStatus,
      showModal(id, basicInfo) {
        console.log('basicInfo', basicInfo)
        if (basicInfo.vehicleNo) {
          if (basicInfo.status > 1) {
            this.findLogisticsMessage(basicInfo.transportNo,basicInfo.logisticsId);
          }
        } else {
          this.$message({
            type: "warning",
            message: "暂无上传车牌信息",
          });
        }

        this.$set(this, "activities", []);
        this.$set(this, "polylinePath", []);
        this.$set(this, "basicInfo", basicInfo);
        this.dialogVisible = true;
      },
      findLogisticsMessage(transportNo,logisticsId) {
        this.$set(this, "activities", []);
        this.$set(this, "polylinePath", []);
        this.loading = true;
        // debugger;
        getWebTrackInfo({
          transportNo, //: 'YD202106100001' 测试用数据
          logisticsId
        }).then((res) => {
          this.loading = false;
          if (res.success) {
            if (res.data.length > 0) {
              this.$set(this, "activities", res.data);
              this.$set(this, "endMark", {
                lng: res.data[res.data.length - 1].lng,
                lat: res.data[res.data.length - 1].lat,
              });
              this.$set(this, "center", {
                lng: res.data[res.data.length - 1].lng,
                lat: res.data[res.data.length - 1].lat,
              });
              this.$set(this, "startMark", {
                lng: res.data[0].lng,
                lat: res.data[0].lat,
              });
              this.$set(this, "polylinePath", res.data);
            }
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 0px;
  }
  .el-form-item-content{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .el-form-item__content{
    display: none;
  }
</style>
